import axios from "axios";
import store from "@/store";
import { apiUri } from "@/config/env";

const instance = axios.create({
  baseURL: apiUri,
  headers: {
    Accept: "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const tokenType = store.getters["auth/tokenType"];
    const accessToken = store.getters["auth/accessToken"];
    config.headers.Authorization = `${tokenType} ${accessToken}`;
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("logout");
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
