import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    component: () => import("@/layouts/Auth.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "login",
        name: "auth.login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "callback",
        name: "auth.callback",
        component: () => import("@/views/auth/Callback.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/App.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/connections",
        name: "connections.index",
        component: () => import("@/views/connections/Index.vue"),
      },
      {
        path: "/connections/:connection",
        name: "connections.view",
        component: () => import("@/views/connections/View.vue"),
      },
      {
        path: "/connections/:connection/edit",
        name: "connections.edit",
        component: () => import("@/views/connections/Edit.vue"),
      },
      {
        path: "/workflows/imports",
        name: "workflows.imports.index",
        component: () => import("@/views/workflows/imports/Index.vue"),
      },
      {
        path: "/workflows/imports/:workflowImport",
        name: "workflows.imports.view",
        component: () => import("@/views/workflows/imports/View.vue"),
      },
      {
        path: "/workflows",
        name: "workflows.index",
        component: () => import("@/views/workflows/Index.vue"),
      },
      {
        path: "/workflows/:workflow",
        name: "workflows.view",
        component: () => import("@/views/workflows/View.vue"),
      },
      {
        path: "/workflows/:workflow/edit",
        name: "workflows.edit",
        component: () => import("@/views/workflows/Edit.vue"),
      },
      {
        path: "/workflows/:workflow/deployments/:deployment",
        name: "workflows.deployments.view",
        component: () => import("@/views/workflows/deployments/View.vue"),
      },
      {
        path: "/workflows/:workflow/logs",
        name: "workflows.logs.index",
        component: () => import("@/views/workflows/logs/Index.vue"),
      },
      {
        path: "/workflows/:workflow/logs/:run_id",
        name: "workflows.logs.view",
        component: () => import("@/views/workflows/logs/View.vue"),
      },
      {
        path: "/settings",
        name: "settings.index",
        component: () => import("@/views/workflows/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isGuest = to.matched.some((record) => record.meta.auth === false);
  const isAuthorized = store.getters["auth/isAuthorized"];

  if (isGuest) {
    if (isAuthorized) {
      next({ name: "dashboard" });
      return;
    }
  } else if (!isAuthorized) {
    next({ name: "auth.login" });
    return;
  }

  next();
});

export default router;
