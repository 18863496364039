import { createApp } from "vue";
import VTooltip from "v-tooltip";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import HighCharts from "highcharts-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";

import "@/polyfills/string";
import "@/registerServiceWorker";
import "@/assets/styles/main.css";

createApp(App)
  .use(store)
  .use(router)
  .use(axios)
  .use(VTooltip)
  .use(PerfectScrollbar)
  .use(HighCharts as any)
  .mount("#app");
