import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "@/store/modules/auth";
import type { AuthState } from "@/store/modules/auth";

export interface RootState {
  auth: AuthState;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
});

export default createStore<RootState>({
  strict: true,
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
  },
  actions: {
    logout({ commit }) {
      commit("reset");
      window.localStorage.removeItem("vuex");
    },
  },
  mutations: {
    reset: (state: any) => {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], null);
      });
    },
  },
});
