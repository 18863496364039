import axios from "@/config/axios";

export interface AuthState {
  token: any;
  user: any;
}

const auth = {
  namespaced: true,

  state: () => ({
    token: null,
    user: null,
  }),

  getters: {
    user(state: any) {
      return state?.user;
    },
    accessToken(state: any) {
      return state.token?.access_token;
    },
    tokenType(state: any) {
      return state.token?.token_type;
    },
    isAuthorized(state: any) {
      return state.token !== null && state.user !== null;
    },
  },

  actions: {
    async login({ commit }: any, token: any) {
      commit("setToken", token);

      const { data: user } = await axios.get("user");
      commit("setUser", user);
    },
  },

  mutations: {
    setToken(state: any, token: any) {
      state.token = token;
    },
    setUser(state: any, user: any) {
      state.user = user;
    },
  },
};

export default auth;
